import { href, Link } from "react-router";
import { DialogWindow } from "~/utils/api.interfaces.enums";
import * as React from "react";
import { MixPanel } from "~/utils/MixPanel";
import { useTranslation } from "react-i18next";
import useMingleRights from "~/components/hooks/useMingleRights";
import { usePagesContext } from "~/routes/layout-pages";

declare let window: DialogWindow;

export const NoTrainingYet = () => {
  const { activeTeam, loggedInUser } = usePagesContext();
  const { t } = useTranslation("translation");
  const { hasAdminRights, hasScheduleRights } = useMingleRights({
    activeTeam,
    loggedInUser,
  });
  return (
    <>
      <div className="w-full bg-white dark:bg-black text-gray-700 dark:text-gray-200 flex justify-center items-center flex-col p-8 flex-1">
        <div className="text-primary mx-auto dark:text-secondary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
            className="inline-block w-12 h-12 mb-4 stroke-current"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18Zm0 4c12.15 0 22-9.85 22-22S36.15 2 24 2 2 11.85 2 24s9.85 22 22 22Z"
              clipRule="evenodd"
            />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M24 14a2 2 0 0 1 2 2v16a2 2 0 1 1-4 0V16a2 2 0 0 1 2-2Z"
              clipRule="evenodd"
            />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M34 24a2 2 0 0 1-2 2H16a2 2 0 1 1 0-4h16a2 2 0 0 1 2 2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h3 className="text-2xl font-bold mb-2 text-center">
          {t("schedulePages.createTraining")}
        </h3>
        <p className="text-center">{t("schedulePages.createTrainingFirst")}</p>
        <Link
          to={href("/create/training")}
          className="btn btn-primary mt-4 btn-outline"
          onClick={(e) => {
            MixPanel.track(
              "hbCreateTraining_training_tap_button_createTraining"
            );
            if (activeTeam?.isArchived) {
              e.preventDefault();
              window.modalTeamIsArchived.showModal();
              return;
            }
            if (!(hasAdminRights || hasScheduleRights)) {
              e.preventDefault();
              window.modalAdminRights.showModal();
            }
          }}
        >
          {t("schedulePages.createTraining")}
        </Link>
      </div>
    </>
  );
};
export const NoEventsYet = () => {
  const { activeTeam, loggedInUser } = usePagesContext();
  const { t } = useTranslation("translation");
  const { hasAdminRights, hasScheduleRights } = useMingleRights({
    activeTeam,
    loggedInUser,
  });
  return (
    <>
      <div className="w-full bg-white dark:bg-black text-gray-700 dark:text-gray-200   flex justify-center items-center flex-col p-8 flex-1">
        <div className="text-primary mx-auto dark:text-secondary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
            className="inline-block w-12 h-12 mb-4 stroke-current"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18Zm0 4c12.15 0 22-9.85 22-22S36.15 2 24 2 2 11.85 2 24s9.85 22 22 22Z"
              clipRule="evenodd"
            />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M24 14a2 2 0 0 1 2 2v16a2 2 0 1 1-4 0V16a2 2 0 0 1 2-2Z"
              clipRule="evenodd"
            />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M34 24a2 2 0 0 1-2 2H16a2 2 0 1 1 0-4h16a2 2 0 0 1 2 2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h3 className="text-2xl font-bold mb-2 text-center">
          {t("schedulePages.createEvent")}
        </h3>
        <p className="text-center">{t("schedulePages.createEventFirst")}</p>
        <Link
          to={href("/create/event")}
          className="btn btn-primary mt-4 btn-outline"
          onClick={(e) => {
            MixPanel.track("hbCreateEvent_event_tap_button_createEvent");
            if (activeTeam?.isArchived) {
              e.preventDefault();
              window.modalTeamIsArchived.showModal();
              return;
            }
            if (!(hasAdminRights || hasScheduleRights)) {
              e.preventDefault();
              window.modalAdminRights.showModal();
            }
            if (activeTeam?.boostStatus?.boostLevel === 0) {
              e.preventDefault();
              window.modalBoostLevelRights.showModal();
              return;
            }
          }}
        >
          {t("schedulePages.createEvent")}
        </Link>
      </div>
    </>
  );
};
export const NoMatchesYet = () => {
  const { activeTeam, loggedInUser } = usePagesContext();
  const { t } = useTranslation("translation");
  const { hasAdminRights, hasScheduleRights } = useMingleRights({
    activeTeam,
    loggedInUser,
  });
  return (
    <>
      <div className="w-full bg-white dark:bg-black text-gray-700 dark:text-gray-200  flex justify-center items-center flex-col lg:p-8 flex-1">
        <div className="text-primary mx-auto dark:text-secondary">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 48 48"
            className="inline-block w-12 h-12 mb-4 stroke-current"
          >
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M24 42c9.941 0 18-8.059 18-18S33.941 6 24 6 6 14.059 6 24s8.059 18 18 18Zm0 4c12.15 0 22-9.85 22-22S36.15 2 24 2 2 11.85 2 24s9.85 22 22 22Z"
              clipRule="evenodd"
            />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M24 14a2 2 0 0 1 2 2v16a2 2 0 1 1-4 0V16a2 2 0 0 1 2-2Z"
              clipRule="evenodd"
            />
            <path
              fill="currentColor"
              fillRule="evenodd"
              d="M34 24a2 2 0 0 1-2 2H16a2 2 0 1 1 0-4h16a2 2 0 0 1 2 2Z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <h3 className="text-2xl font-bold mb-2 text-center">
          {t("schedulePages.createMatch")}
        </h3>
        <p className="text-center">{t("schedulePages.createMatchFirst")}</p>
        <Link
          to={href("/create/match/")}
          className="btn btn-primary mt-4 btn-outline"
          onClick={(e) => {
            MixPanel.track("hbCreateMatch_match_tap_button_createMatch");

            if (activeTeam?.isArchived) {
              e.preventDefault();
              window.modalTeamIsArchived.showModal();
              return;
            }
            if (!(hasAdminRights || hasScheduleRights)) {
              e.preventDefault();
              window.modalAdminRights.showModal();
              return;
            }
          }}
        >
          {t("schedulePages.createMatch")}
        </Link>
      </div>
    </>
  );
};
